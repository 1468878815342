import * as React from "react";
import { Link } from "@flogy/gatsby-theme-fgs-layout";
import { StaticImage } from "gatsby-plugin-image";
import { SiteLayout } from "../components/SiteLayout";
import { Seo } from "../components/Seo";

const UeberUnsPage: React.FC<{}> = () => (
  <SiteLayout>
    <section className="bg-transparent text-white px-10 md:px-16 py-12 md:p-24">
      <div className="container mx-auto max-w-5xl">
        <h1>Wer dahinter steckt</h1>
      </div>
    </section>
    <section className="bg-white text-slate-700 px-10 md:px-16 py-12 md:p-24">
      <div className="container mx-auto max-w-5xl">
        <StaticImage
          src="../images/team.jpg"
          alt="Teamfoto"
          placeholder="blurred"
        />
        <p>
          Wir sind ein junges, engagiertes Team und gehören mit exSPIRIence zu
          der <Link href="https://emk-windisch.ch">EMK Windisch</Link>.
        </p>
      </div>
    </section>
  </SiteLayout>
);

export const Head = ({ location: { pathname } }) => (
  <Seo title="Über uns" relativePageUrl={pathname} />
);

export default UeberUnsPage;
